import styled from 'styled-components/macro';

import { getBreakpoint, getColor, getFontWeight } from '../util/theme';

export const BrandBody = styled.div`
  h2 {
    color: #2d536f;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    list-style-type: none;

    @media (min-width: ${getBreakpoint('lg')}) {
      flex-direction: row;
      justify-content: center;

      padding: 50px 0;
    }

    li {
      display: inline-block;
      font: bold;
      margin: 0 15px;

      text-align: center;

      width: 175px;

      &:hover {
        a {
          color: ${getColor('accentGreen')};
          img {
            transform: scale(1.1);
          }
        }
      }

      a {
        color: ${getColor('primaryBlue')};
        font-size: 1.5rem;
        font-weight: ${getFontWeight('medium')};
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
`;
