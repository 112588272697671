import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components/macro';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../util/theme';

const brands = [
  {
    name: 'OneTrust Home Loans',
    route: 'brands/othl',
    slug: 'othl',
  },
  {
    name: 'Calcon Mutual Mortgage',
    route: 'brands/calcon',
    slug: 'calcon',
  },
  {
    name: 'Tabor Mortgage Group',
    route: 'brands/tabor',
    slug: 'tabor',
  },
  {
    name: 'Team One Home Loans',
    route: 'brands/teamone',
    slug: 'teamone',
  },
];

const BrandList = styled.div`
  display: flex;
  justify-content: space-between;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${getBreakpoint('lg')}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  padding: 45px 0;
  width: 100%;

  div {
    border: 1px solid #e9e9e9;
    color: ${getColor('black')};

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 0;

    width: 100%;
    height: 25p;

    &:hover {
      background-color: ${getColor('accentGreen')};

      a {
        color: ${getColor('white')};
      }
    }

    &.active {
      background-color: ${getColor('accentOrange')};

      a {
        color: ${getColor('white')};
      }
    }

    a {
      color: ${getColor('black')};
      font-size: 2rem;
      font-weight: ${getFontWeight('medium')};
      text-align: center;
      text-decoration: none;

      width: 100%;
    }
  }
`;

const BrandSelector = ({ active }) => {
  const drawSelectors = brands => {
    return brands.map(brand => {
      const { name, route, slug } = brand;
      return active === slug ? (
        <div className="active" key={name}>
          <Link to={`/${route}`}>{name}</Link>
        </div>
      ) : (
        <div key={name}>
          <Link to={`/${route}`}>{name}</Link>
        </div>
      );
    });
  };

  return <BrandList>{drawSelectors(brands)}</BrandList>;
};

export default BrandSelector;
